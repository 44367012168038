.App {
    font-family: sans-serif;
    text-align: center;
}

.emptyProgressBar {
    background-color: black;
    border-radius: 100px;
    height: 13px;
    overflow: hidden;
    position: relative;
}
/* 
.ant-spin-dot-item {
    background-color: white !important;
}   */

.fillingProgressBar {
    border-radius: 100px;
    height: 100%;
    width: 100%;
    position: absolute;
    animation: fillProgressBar 2s linear infinite; /* Apply the animation */
}

/* @keyframes fillProgressBar {
    0% {
        left: -100%; /* Start from the left side * /
    }
    100% {
        left: 0%; /* Move to the right side * /
    }
} */

.percentage {
    color: #5273e6;
    font-size: 46px;
}

.mainProgressBarDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}